<template>
  <v-container id="dashboard" fluid tag="section">

    <v-form ref="form">
      <base-material-card icon="mdi-account-multiple">
        <slot slot="title">Users</slot>
        <slot slot="rightButtons">
          <toggle-button text="Add a new User" openIcon="mdi-plus" @toggle="showAdd = true" :showing="showAdd"/>
        </slot>
        <p>These users are the administrators of your account.  To add a new user, first ask them to set up an account by going here:
           <a :href="makeURL()" target="_blank">{{makeURL()}}</a>, and then click 
           <v-btn class="mx-0" small color="secondary" @click="showAdd = true">Add</v-btn> to add them to your account.
        </p>
        <v-row>
          <v-col cols="12" md="12">
            <v-row class="ml-2 mr-0" v-if="showAdd">
              <v-text-field label="Email of User to Add" v-model="email" :rules="[utils.requiredRule]"/>
              <v-btn class="mt-4 ml-2" color="success" small @click="add()">Add</v-btn>
            </v-row>
            <span>
              <span v-for="(user, i) in users" :key="i">
                <v-row class="ml-n1 mr-n1">
                  <v-col cols="6">{{user.email}}</v-col>
                  <v-col cols="5">{{user.name}}</v-col>
                  <v-col cols="1">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="error" @click="remove(user)">mdi-close-circle</v-icon>
                      </template>
                      Remove Access
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-divider/>
              </span>
            </span>
          </v-col>
        </v-row>
      </base-material-card>
    </v-form>

  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';
  
  export default {
    name: 'Events',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  
    
    computed: {
      utils: function () {
        return Utils;
      }
    },

    data: () => ({
      email: "",
      users: [],
      user: {},
      showAdd: false,
      boxOffice: {},
    }),

    created() {
      this.boxOffice.id = this.$route.params.id;
      this.load();
    },
    
    methods: {

      makeURL() {
        var url = window.location.origin + "/Dashboard/Profile";
        return url;
      },
      
      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice);
        this.readUsers();
      },
      
      async readUsers() {
        this.users = await Api.post(this, "BoxOffice", "listUsers", this.boxOffice);
      },

      async add() {
        if (! await Utils.validate(this.$refs.form) ) {
          return;
        }
        var payload = {};
        payload.email = this.email;
        payload.id = this.boxOffice.id;
        await Api.post(this, "BoxOffice", "addUser", payload);
        this.showAdd = false;
        this.readUsers();
      },

      async remove(user) {
        var payload = {};
        payload.userId = user.id;
        payload.id = this.boxOffice.id;
        await Api.post(this, "BoxOffice", "removeUser", payload);
        this.readUsers();
      },
    }
  }

</script>
